import { Overlay, OverlayModule, ScrollStrategy } from "@angular/cdk/overlay";
import { CommonModule } from "@angular/common";
import { AfterViewInit, Component, ElementRef, inject, Input, OnInit, ViewChild } from "@angular/core";
import { FormControl, FormGroup, PristineChangeEvent, ReactiveFormsModule, TouchedChangeEvent, ValueChangeEvent } from "@angular/forms";
import { MatCalendar, MatDatepickerModule } from "@angular/material/datepicker";
import { MatIconModule } from "@angular/material/icon";
import { BehaviorSubject } from "rxjs";
import { DefaultComponent } from "src/app/default.component";
import { PrefixTemplate } from "../../PrefixTemplate";
import { PrefixValidator } from "../../PrefixValidator";
import { Regex } from "../../types/Regex";
import { TemplateHTMLComponent } from "../template-html/template-html.component";

@Component({
  selector: "app-template-date",
  imports: [ReactiveFormsModule, TemplateHTMLComponent, CommonModule, MatIconModule, OverlayModule, MatDatepickerModule],
  templateUrl: "./template-date.component.html",
  styleUrl: "./template-date.component.less",
})
export class TemplateDateComponent extends DefaultComponent implements PrefixTemplate<string>, OnInit, AfterViewInit {
  @Input({ required: true })
  public control: FormControl<string | null>;

  @Input()
  public value: string | null;

  @Input()
  public regex: Regex;

  @Input()
  public required: boolean;

  @Input()
  public disabled: boolean;

  @Input()
  public characters: string | null;

  @Input()
  public label: string | null;

  @ViewChild("idate")
  public dateInput: ElementRef<HTMLInputElement> | null;

  @ViewChild("imonth")
  public monthInput: ElementRef<HTMLInputElement> | null;

  @ViewChild("iyear")
  public yearInput: ElementRef<HTMLInputElement> | null;

  @ViewChild("datepicker")
  public datepicker: MatCalendar<Date> | null;

  public pickerOpen: boolean;

  public selected$: BehaviorSubject<Date | null>;

  public group: FormGroup<{
    date: FormControl<string | null>;
    month: FormControl<string | null>;
    year: FormControl<string | null>;
  }>;

  public scroll: ScrollStrategy;

  private overlay: Overlay;

  public constructor() {
    super();
    this.overlay = inject(Overlay);

    this.control = new FormControl(null);
    this.value = null;
    this.regex = null;
    this.required = false;
    this.disabled = false;
    this.characters = null;
    this.label = null;

    this.datepicker = null;
    this.pickerOpen = false;
    this.dateInput = null;
    this.monthInput = null;
    this.yearInput = null;
    this.scroll = this.overlay.scrollStrategies.noop();
    this.selected$ = new BehaviorSubject<Date | null>(null);
    this.group = new FormGroup({
      date: new FormControl<string | null>(null),
      month: new FormControl<string | null>(null),
      year: new FormControl<string | null>(null),
    });
  }

  public ngOnInit(): void {
    if (!this.disabled) {
      this.addSubscription(
        this.group.events.subscribe((e) => {
          if (e instanceof ValueChangeEvent) {
            const { date, month, year } = e.value;
            if (date?.length === 2 && month?.length === 2 && year?.length === 4) {
              const d = new Date(`${year}-${month}-${date}`);
              if (parseInt(month) === d.getMonth() + 1) {
                this.selected$.next(d ? (isNaN(d.getTime()) ? null : d) : null);
              } else {
                this.selected$.next(null);
              }
            } else {
              this.selected$.next(null);
            }
          }
          if (e instanceof TouchedChangeEvent) {
            e.touched ? this.control.markAsTouched() : this.control.markAsUntouched();
          }
          if (e instanceof PristineChangeEvent) {
            e.pristine ? this.control.markAsPristine() : this.control.markAsDirty();
          }
        }),
        this.selected$.subscribe((selected) => {
          this.datepicker?.focusActiveCell();
          if (selected) {
            const { date, month, year } = this.group.value;
            this.control.setValue(`${year}-${month}-${date}`);
          } else {
            this.control.setValue(null);
          }
        }),
      );
      const control = this.control;
      if (control) {
        this.control.setValue(this.value);
        if (this.value) {
          const [year, month, date] = this.value.split("-", 3);
          this.group.setValue({ year, month, date });
        }
        this.addValidators(control);
      } else {
        throw new Error("Undefined control");
      }
    }
  }

  public ngAfterViewInit(): void {
    this.scroll = this.overlay.scrollStrategies.reposition();
  }

  public onDateSelected(selected: Date): void {
    this.group.patchValue({
      date: selected.getDate().toString().padStart(2, "0"),
      month: (selected.getMonth() + 1).toString().padStart(2, "0"),
      year: selected.getFullYear().toString().padStart(4, "0"),
    });
    this.group.markAsTouched();
    this.group.markAsDirty();
    this.pickerOpen = false;
  }

  public onInputBlur(key: keyof typeof this.group.value): void {
    const value = this.group.value;

    const getMonth = (): number | null => {
      if (!value.month) return null;
      const month = parseInt(value.month);
      if (isNaN(month)) return null;
      if (month < 1) return 1;
      if (month > 12) return 12;
      return month;
    };
    const getDay = (): number | null => {
      if (!value.date) return null;
      const date = parseInt(value.date);
      if (isNaN(date)) return null;
      if (date < 1) return 1;
      if (date > 31) return 31;
      return date;
    };
    const getYear = (): number | null => {
      if (!value.year) return null;
      const year = parseInt(value.year);
      if (isNaN(year)) return null;
      if (year < 1753) return 1753;
      if (year > 2099) return 2099;
      return year;
    };

    switch (key) {
      case "date":
        {
          const date = getDay();

          this.group.controls[key].setValue(date?.toString().padStart(2, "0") ?? null);
        }
        break;
      case "month":
        {
          const month = getMonth();

          this.group.controls[key].setValue(month?.toString().padStart(2, "0") ?? null);
        }
        break;
      case "year":
        {
          const year = getYear();
          this.group.controls[key].setValue(year?.toString().padStart(4, "0") ?? null);
        }
        break;
    }
  }

  public onBeforeInput(event: InputEvent): void {
    const pattern = /[0-9]/;
    if (event.data) {
      if (!pattern.test(event.data)) {
        event.preventDefault();
      }
    }
  }

  public onKeyUp(event: KeyboardEvent, source: keyof typeof this.group.value): void {
    switch (true) {
      case event.code === "ArrowLeft" && source === "month":
        this.dateInput?.nativeElement.focus();
        break;
      case event.code === "Minus" && source === "date":
      case event.code === "Slash" && source === "date":
      case event.code === "ArrowLeft" && source === "year":
      case event.code === "ArrowRight" && source === "date":
        this.monthInput?.nativeElement.focus();
        break;
      case event.code === "Minus" && source === "month":
      case event.code === "Slash" && source === "month":
      case event.code === "ArrowRight" && source === "month":
        this.yearInput?.nativeElement.focus();
        break;
    }
  }

  private addValidators(control: FormControl<string | null>): void {
    if (this.regex) control.addValidators([PrefixValidator.regex(this.regex)]);
    if (this.required) control.addValidators([PrefixValidator.required()]);
    control.addValidators([PrefixValidator.minDate("1753-01-01")]);
    control.addValidators([PrefixValidator.maxDate("2099-12-31")]);
    control.addValidators([PrefixValidator.validDate(this.group)]);
    control.updateValueAndValidity();
  }
}
