import { NgTemplateOutlet } from "@angular/common";
import { Component, Input, OnInit } from "@angular/core";
import { FormControl, PristineChangeEvent, ReactiveFormsModule, TouchedChangeEvent } from "@angular/forms";
import { DefaultComponent } from "src/app/default.component";
import { PrefixTemplate } from "../../PrefixTemplate";
import { PrefixValidator } from "../../PrefixValidator";
import { MaxLength } from "../../types/MaxLength";
import { MinLength } from "../../types/MinLength";

@Component({
  selector: "app-template-hexadecimal",
  imports: [ReactiveFormsModule, NgTemplateOutlet],
  templateUrl: "./template-hexadecimal.component.html",
  styleUrl: "./template-hexadecimal.component.less",
})
export class TemplateHexadecimalComponent extends DefaultComponent implements PrefixTemplate<string>, OnInit {
  @Input({ required: true })
  public control: FormControl<string | null> | null;

  @Input()
  public value: string | null;

  @Input()
  public minlength: MinLength;

  @Input()
  public maxlength: MaxLength;

  @Input()
  public required: boolean;

  @Input()
  public disabled: boolean;

  @Input()
  public characters: string | null;

  @Input()
  public label: string | null;

  @Input()
  public placeholder: string;

  public pickerControl: FormControl<string | null>;

  public constructor() {
    super();
    this.control = null;
    this.value = null;
    this.minlength = 6;
    this.maxlength = 6;
    this.characters = "0123456789abcdef";
    this.required = false;
    this.disabled = false;
    this.label = null;
    this.placeholder = "";
    this.pickerControl = new FormControl<string | null>(null);

    this.addSubscription(
      this.pickerControl.valueChanges.subscribe((value) => {
        if (value) {
          this.control?.patchValue(value?.substring(1) ?? null);
        }
      }),
      this.pickerControl.events.subscribe((e) => {
        if (e instanceof TouchedChangeEvent) {
          e.touched ? this.control?.markAsTouched() : this.control?.markAsUntouched();
        }
        if (e instanceof PristineChangeEvent) {
          e.pristine ? this.control?.markAsPristine() : this.control?.markAsDirty();
        }
      }),
    );
  }

  public ngOnInit(): void {
    const control = this.control;
    if (control) {
      this.addValidators(control);
    } else {
      throw new Error("Undefined control");
    }
  }

  public onBeforeInput(event: InputEvent): void {
    const input = event.data?.split("") ?? [];
    const invalid = !!input.filter((str) => this.characters && !this.characters.includes(str)).length;
    if (invalid) event.preventDefault();
  }

  private addValidators(control: FormControl<string | null>): void {
    if (this.minlength) control.addValidators([PrefixValidator.minlength(this.minlength)]);
    if (this.maxlength) control.addValidators([PrefixValidator.maxlength(this.maxlength)]);
    if (this.required) control.addValidators([PrefixValidator.required()]);

    control.updateValueAndValidity();
  }
}
